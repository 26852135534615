import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Logo from "../components/logo"
import AboutImage from "../components/about_image";
import ServicesImage from "../components/service_image";
import HealthImage from "../components/health_image";
import ContactImage from "../components/contact_image";

const AboutUs = () => (
    <Layout>
        <SEO title="About US" description="We provide silo cleaning for the following industries - Flour Milling and associated industries, Bakeries, Feed Mills, Brewery industry, Drinks industry, Rice Milling, Plastics manufacture, Sweet and confectionery industries"/>
        <div className={'max-w-screen-lg mx-auto'}>
                <Logo />
        </div>
        <div className={'bg-white p-4 max-w-screen-lg mx-auto'}>
        <h1 className={'text-2xl text-indigo-700'}>About Us</h1>
            <p className={'my-2'}>Silo Cleaning Services has been operating since 1975 carrying out all associated aspects of silo
                    cleaning and high level cleaning throughout the UK. This makes us one of the most
                    established businesses to offer our services to the following sectors:</p>
            <ul className={'list-disc ml-6'}>
                    <li><strong>Flour Milling and associated industries</strong></li>
                    <li><strong>Bakeries</strong></li>
                    <li><strong>Feed Mills</strong></li>
                    <li><strong>Brewery industry</strong></li>
                    <li><strong>Drinks industry</strong></li>
                    <li><strong>Rice Milling</strong></li>
                    <li><strong>Plastics manufacture</strong></li>
                    <li><strong>Sweet and confectionery industries</strong></li>
            </ul>
            <p className={'my-2'}>The majority of work undertaken for customers has been via recommendations and repeat contracts,
                    which is testament to our high standards, safe and professional manner, highly trained
                    operatives and our commitment and flexibility to our customers' needs.</p>
            <p className={'my-2'}>Our ethos is to help our customers achieve the highest standards of hygiene.</p>
        </div>
            <div className={'max-w-screen-lg mx-auto'}>
                    <div className={'flex flex-wrap text-white mt-4'}>
                            <div className={'flex-1 pb-2 my-2 mr-2 bg-yellow-500'}>
                                    <AboutImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>about us</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/about-us">More Information</Link>
                                    </div>
                            </div>
                            <div className={'flex-1 pb-2 m-2 bg-orange-500'}>
                                    <ServicesImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>our services</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/our-services">More Information</Link>
                                    </div>
                            </div>
                            <div className={'flex-1 pb-2 m-2 bg-teal-700'}>
                                    <HealthImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>health & safety</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/health-and-safety">More Information</Link>
                                    </div>
                            </div>
                            <div className={'flex-1 pb-2 m-2 ml-2 bg-indigo-700'}>
                                    <ContactImage />
                                    <div className={'px-4'}>
                                            <h2 className={'text-2xl font-normal mb-2'}>contact us</h2>
                                    </div>
                                    <div className={'text-center'}>
                                            <Link className={'bg-white rounded-md text-gray-800 p-2'} to="/contact-us">More Information</Link>
                                    </div>
                            </div>
                    </div>
            </div>
    </Layout>
)

export default AboutUs
